import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';
import { MaterialSymbolsAssignmentRounded } from '@/assets/icons/funds/MaterialSymbolsAssignmentRounded';
import PullToRefresh from '@/components/base/basePullToRefresh';
import BaseTopNav from '@/components/base/baseTopNav';
import { FINANCIAL_MANAGEMENT_LIST_ENUM } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import AItradeItemCard from '@/pages/mine/aItrade/components/AItradeItemCard';
import { getAIListApi } from '@/services/api/requestApi';
import { history } from '@umijs/max';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  const [pageParams, setPageParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  const params = useMemo(() => {
    return {
      ...pageParams,
      financeType:
        FINANCIAL_MANAGEMENT_LIST_ENUM.QUANTIFICATION_FINANCIAL_MANAGEMENT,
    };
  }, [pageParams]);

  const { list, loading, loadMore, error, total, ...resetContractPageProps } =
    usePaginationPage({
      fn: getAIListApi,
      params,
      setParams: setPageParams,
    });

  return (
    <div>
      <BaseTopNav
        title={t('AI交易')}
        rightNode={
          <div className="w-fit flex items-center ">
            {loading && (
              <div className="flex items-center justify-center w-fit h-full">
                <SvgSpinnersBarsRotateFade />
              </div>
            )}
            <MaterialSymbolsAssignmentRounded
              onClick={() => {
                history.push(
                  PageEnum.AI_ORDER +
                    '?financeType=' +
                    FINANCIAL_MANAGEMENT_LIST_ENUM.QUANTIFICATION_FINANCIAL_MANAGEMENT,
                );
              }}
              className="text-backContrastColor  cursor-pointer p-4 box-content"
            />
          </div>
        }
      />
      {
        <>
          <div className="px-4 my-3">
            <div className="text-xl text-backContrastColor font-semibold">
              {t('简单赚币')}
            </div>
            <div className="text-sm leading-[28px] text-auxiliaryTextColor">
              {t('AI量化交易，解放你的双手，让LIBERTY AI 4.0为你创造利润')}
            </div>
          </div>
          {/* 图片 */}
          <div className="w-full mb-[12px] px-4">
            <img src={require('@/assets/img/trade/AITrade.png')} alt="" />
          </div>
          <PullToRefresh
            loadMore={loadMore}
            total={total}
            list={list}
            error={error}
            loading={loading}
            loadingDisplay={false}
            {...resetContractPageProps}
          >
            <div className="px-4 flex flex-col gap-[8px]">
              {/* //渲染列表 */}
              {list?.map?.((item: any, index: any) => {
                return (
                  <AItradeItemCard
                    card={{
                      ...item,
                      financeType:
                        FINANCIAL_MANAGEMENT_LIST_ENUM.QUANTIFICATION_FINANCIAL_MANAGEMENT,
                    }}
                    index={index}
                    isQuantification={true}
                    key={item?.id}
                  />
                );
              })}
            </div>
          </PullToRefresh>
        </>
      }
    </div>
  );
};
