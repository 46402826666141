import PageEnum from '@/enums/pageEnum';
import RenderUtil from '@/utils/RenderUtil';
import { history } from '@umijs/max';
import { useTranslation } from 'react-i18next';

/**
 * item 卡片
 */
export default (props: any) => {
  const { card,isQuantification, classNames } = props;
  const { t } = useTranslation();
  return (
    <div
      className="flex items-center  justify-between rounded-md"
      key={card?.id}
      onClick={() => {
        history.push(PageEnum.BUYAI+`?financeType=${card?.financeType}`, card);
      }}
    >
      <div className="flex items-center gap-2 flex-2">
        <img
          src={card?.icon}
          className="w-[32px] h-[32px] rounded-full object-cover"
          alt=""
        />
        <div className="text-base text-backContrastColor">{card?.name?card?.name:card?.coinName}</div>
        <div className={`bg-backgroundAuxiliaryColor text-auxiliaryTextColor px-[6px] py-[2px]  rounded-md text-xs ${classNames?.tag}`}>
          { t("{{date}}天",{date:card?.expirationDate})}
        </div>
      </div>

      {/* <div className='mr-2'>
        {card?.maximumInvestment}
      </div> */}
      <div className="flex w-[40px]  text-right  justify-end items-center gap-[1px] text-backContrastColor text-base">
        {/* 定期不展示 */}
        {isQuantification&&(
          <>
          <span>{RenderUtil.FormatAmount(card?.displayMinWinRate,2)}%</span>
            <span>-</span>
          </>
          )
        }
        <span>{RenderUtil.FormatAmount(card?.displayMaxWinRate,2)}%</span>
      </div>
    </div>
  );
};
