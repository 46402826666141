import PageEnum from '@/enums/pageEnum';
import HomeTrend from '@/pages/home/components/HomeTrend';
import RenderUtil from '@/utils/RenderUtil';
import { cn } from '@nextui-org/react';
import { history, useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';
/**
 * ai交易item 卡片
 */
export default (props: any) => {
  const { card, isQuantification, classNames } = props;
  const { t } = useTranslation();
  // 红涨绿跌设置
  const { currentUpClass, currentDownClass } = useModel('system');
  return (
    <div
      className="w-full p-4 flex flex-wrap border-[1px] border-solid border-borderColor rounded-[8px] "
      key={card?.id}
    >
      <div className="w-full flex justify-between items-start">
        <div className="min-w-[50%] !max-w-[80%] w-full flex flex-wrap">
          <div className="w-full text-[16px] leading-[22px] font-[600px] mb-[2px]">
            {/* {card?.currencyRespDTOS?.symbol} */}
            {card?.name?card?.name:card?.currencyRespDTOS?.symbol}
          </div>
          <div className="w-full">
            {!card?.direction && (
              <>
                <span
                  className={cn(
                    `!text-auxiliaryTextColor !bg-auxiliaryTextColor/10 h-fit font-normal rounded-[4px] p-1 py-[2px] text-xs`,
                  )}
                >
                  {t('中性')}
                </span>
              </>
            )}
            {card?.direction === 1 && (
              <span
                className={cn(
                  currentUpClass,
                  `bg-successColor !text-[#fff] h-fit font-normal rounded-[4px] p-1 py-[2px] text-xs`,
                )}
              >
                {t('做多')}
              </span>
            )}
            {card?.direction === 2 && (
              <span
                className={cn(
                  currentDownClass,
                  `bg-errorColor !text-[#fff] h-fit font-normal rounded-[4px] p-1 py-[2px] text-xs`,
                )}
              >
                {t('做空')}
              </span>
            )}
            <span
              className={cn(
                `ml-[6px] !text-auxiliaryTextColor !bg-auxiliaryTextColor/10 h-fit font-normal rounded-[4px] p-1 py-[2px] text-xs`,
              )}
            >
              10x
            </span>
          </div>
        </div>
        <div className="min-w-fit text-[14px] leading-[22px] text-auxiliaryTextColor ">
          {t('周期 {{date}} 天', { date: card?.expirationDate ?? '--' })}
        </div>
      </div>
      {/* 虚线 */}
      <div className="w-full border-[1px] border-borderColor border-dashed my-3"></div>
      {/*  */}
      <div className="w-full flex flex-wrap">
        <div className="w-full flex justify-between">
          <div
            className={cn(
              currentUpClass,
              `flex flex-wrap items-center gap-[1px]  text-[16px] leading-[24px] font-[600px]`,
            )}
          >
            <div className="w-full">
              {isQuantification && (
                <>
                  <span>
                    {RenderUtil.FormatAmount(card?.displayMinWinRate)}%
                  </span>
                  <span>-</span>
                </>
              )}
              <span>
                {RenderUtil.FormatAmount(card?.displayMaxWinRate)}%
              </span>
            </div>
            <span className="text-[12px] leading-[16px] font-[400px] text-auxiliaryTextColor">
              {t('收益率')}
            </span>
          </div>
          <div className='min-w-[110px] min-h-[44px]'>
            {/* <img
              src={require('@/assets/img/trade/AITradeLine.png')}
              className="h-[44px]"
              alt=""
            /> */}
            <HomeTrend
              LineColor={'transparent'}
              isUp={card?.currencyRespDTOS?.pe > 0}
              data={card?.manualKLine?.split?.(',')  ?? card?.currencyRespDTOS?.currencyCandlestickApiDTO?.map?.(
                (i: any) => i?.c,
              )}
              canvasWidth={'calc(100% )'}
              canvasHeight={'30px'}
            />
          </div>
        </div>
      </div>
      {/* 最大撤回 */}
      <div className="w-full my-[8px] flex justify-between">
        <div className="text-[12px] leading-[16px] font-[400px] text-auxiliaryTextColor">
          {t('每日交易次数')}
        </div>
        <div className="text-[12px] leading-[16px] font-[400px] text-backContrastColor">
          {card?.dayFrequency}
        </div>
      </div>
      {/* 购买 */}
      <div className=" w-full flex justify-between">
        <div className="text-[14px] leading-[20px] font-[600px]">
          {t('{{data}}手起投', {
            data: Math.floor(card?.minimumInvestment / card?.handNum),
          })}
        </div>
        <div
          className={cn(
            `max-h-fit bg-[#2DB880] text-[#fff] px-[20px] py-[5px] text-[14px] leading-[22px] font-[600px] rounded-[8px]`,
          )}
          onClick={() => {
            history.push(
              PageEnum.BUYAI + `?financeType=${card?.financeType}`,
              card,
            );
          }}
        >
          {t('购买')}
        </div>
      </div>
      {/* <div className="flex items-center gap-2">
        <img
          src={card?.icon}
          className="w-[32px] h-[32px] rounded-full object-cover"
          alt=""
        />
        <div className="text-base text-backContrastColor">{card?.coinName}</div>
        <div className={`bg-backgroundAuxiliaryColor text-auxiliaryTextColor px-[6px] py-[2px]  rounded-md text-xs ${classNames?.tag}`}>
          { t("{{date}}天",{date:card?.expirationDate})}
        </div>
      </div>
      <div className="flex items-center gap-[1px] text-backContrastColor text-base">
        {isQuantification&&(
          <>
          <span>{RenderUtil.FormatAmount(card?.displayMinProfitRate)}%</span>
            <span>-</span>
          </>
          )
        }
        <span>{RenderUtil.FormatAmount(card?.displayMaxProfitRate)}%</span>
      </div> */}
    </div>
  );
};
