import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';
import { MaterialSymbolsAssignmentRounded } from '@/assets/icons/funds/MaterialSymbolsAssignmentRounded';
import PullToRefresh from '@/components/base/basePullToRefresh';
import BaseTopNav from '@/components/base/baseTopNav';
import { FINANCIAL_MANAGEMENT_LIST_ENUM } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import AItradeRegularItemCard from '@/pages/mine/aItrade/components/AItradeRegularItemCard';
import { getAIListApi } from '@/services/api/requestApi';
import { history } from '@umijs/max';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  const [pageParams, setPageParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });
  const params = useMemo(() => {
    return {
      ...pageParams,
      financeType: FINANCIAL_MANAGEMENT_LIST_ENUM.REGULAR_FINANCIAL_MANAGEMENT,
    };
  }, [pageParams]);

  const { list, loading, loadMore, error, total, ...resetContractPageProps } =
    usePaginationPage({
      fn: getAIListApi,
      params,
      setParams: setPageParams,
    });
  return (
    <div>
      <BaseTopNav
        title={t('理财')}
        rightNode={
          <div className="w-fit flex items-center ">
            {loading && (
              <div className="flex items-center justify-center w-fit h-full">
                <SvgSpinnersBarsRotateFade />
              </div>
            )}
            <MaterialSymbolsAssignmentRounded
              onClick={() => {
                history.push(
                  PageEnum.AI_ORDER +
                    '?financeType=' +
                    FINANCIAL_MANAGEMENT_LIST_ENUM.REGULAR_FINANCIAL_MANAGEMENT,
                );
              }}
              className="text-backContrastColor  cursor-pointer p-4 box-content"
            />
          </div>
        }
      />
      <div className="px-4 my-3">
        <div className="text-xl text-backContrastColor font-semibold">
          {t('简单赚币')}
        </div>
        <div className="text-sm leading-[28px] text-auxiliaryTextColor">
          {t('借出闲置资金，最大化被动收益。')}
        </div>
      </div>
      {/* <BaseTabs
        value={currentTab}
        options={tabs}
        tabsPropsProps={{
          classNames: {
            tabList: 'px-4 pb-0',
          },
        }}
        onChange={setCurrentTab}
      />
      <Divider className="!bg-backgroundAuxiliaryColor mb-4" /> */}
      {/* 图片 */}
      <div className="w-full mb-[20px] px-4">
        <img src={require('@/assets/img/trade/regular.png')} alt="" />
      </div>
      <PullToRefresh
        loadMore={loadMore}
        total={total}
        list={list}
        error={error}
        loading={loading}
        loadingDisplay={false}
        {...resetContractPageProps}
      >
        <div className="px-4 flex flex-col gap-[32px]">
          <div className="text-xs text-auxiliaryTextColor flex items-center justify-between">
            <span className='flex-2'>{t('币种')}</span>
            {/* <span className='mr-2'>{t('最大投资金额')}</span> */}
            <span className=' w-[40px] text-right'>{t('收益率')}</span>
          </div>
          {/* //渲染列表 */}
          {list?.map?.((item: any, index: any) => {
            return (
              <AItradeRegularItemCard
                card={{
                  ...item,
                  financeType:
                    FINANCIAL_MANAGEMENT_LIST_ENUM.REGULAR_FINANCIAL_MANAGEMENT,
                }}
                classNames={{ tag: '!bg-[#FFA6001A] !text-[#FFA600]' }}
                index={index}
                isQuantification={false}
                key={item?.id}
              />
            );
          })}
        </div>
      </PullToRefresh>
    </div>
  );
};
