import { MaterialSymbolsAssignmentRounded } from '@/assets/icons/funds/MaterialSymbolsAssignmentRounded';
import BaseTopNav from '@/components/base/baseTopNav';
import { FINANCIAL_MANAGEMENT_LIST_ENUM } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import { history, useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';

export default () => {
  const { isPc } = useModel('system');
  const { t } = useTranslation();

  return (
    <>
      <BaseTopNav
        title={t('AI交易')}
        rightNode={
          <MaterialSymbolsAssignmentRounded
            onClick={() => {
              history.push(
                PageEnum.AI_ORDER +
                  '?financeType=' +
                  FINANCIAL_MANAGEMENT_LIST_ENUM.QUANTIFICATION_FINANCIAL_MANAGEMENT,
              );
            }}
            className="text-backContrastColor  cursor-pointer p-4 box-content"
          />
        }
      />
      <div
        className={`w-full  text-center  ${
          isPc ? 'h-[600px]' : 'h-[100vh] bg-[#020102]'
        }`}
        style={{
          backgroundImage: `url('${require(`@/assets/img/trade/${
            !isPc ? 'AIempty_top_bg.png' : 'AIempty_bg_pc.jpg'
          }`)}')`,
          backgroundRepeat: 'no-repeat',
          backgroundClip: 'content-box',
          backgroundSize: '100%',
        }}
      >
        <div className="w-full flex flex-wrap justify-center pt-[82px] ">
          <div className="w-full flex flex-wrap justify-center text-[#fff] text-[24px] leading-[34px] font-[400px]">
            <span className="w-full">{t('你好')}</span>
            <span className="w-full">{t('未来财富AI 5.0')}</span>
          </div>
          <div
            className=" flex justify-center mt-[20px]"
            style={{
              backgroundImage: `url('${require(`@/assets/img/trade/AIempty_text.png`)}')`,
              backgroundRepeat: 'no-repeat',
              backgroundClip: 'content-box',
              backgroundSize: '100% 100%',
            }}
          >
            <span className="px-[68px]  my-[10px] w-fit text-[16px] leading-[16px] font-[600px] text-[#fff]">
              {t('敬请期待')}
            </span>
          </div>
        </div>
        {/*  */}
        {!isPc && (
          <div
            className={`w-full h-[379px] bg-cover bg-center bg-no-repeat absolute inset-x-0 bottom-[-50px]`}
            style={{
              backgroundImage: `url(${require(`@/assets/img/trade/AIempty_bottom_bg.png`)})`,
              backgroundRepeat: 'no-repeat',
              backgroundClip: 'content-box',
              backgroundSize: '100% 100%',
            }}
          />
        )}
      </div>
    </>
  );
};
