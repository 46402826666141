import { FINANCIAL_MANAGEMENT_LIST_ENUM } from '@/enums/businessEnum';
import useUrlState from '@ahooksjs/use-url-state';
import { useModel } from '@umijs/max';
import AItradeQuantificationView from './components/AItradeQuantificationView';
import AItradeRegularView from './components/AItradeRegularView';
import AItradeTrendEmpty from './components/AItradeTrendEmpty';

export default () => {
  const [urlState] = useUrlState() || {};
  const { appInfo } = useModel('appInfo');

  return (
    <>
      {/* AI理财 */}
      {+urlState?.type ===
        +FINANCIAL_MANAGEMENT_LIST_ENUM.QUANTIFICATION_FINANCIAL_MANAGEMENT && (
        <>
          {/* 后台卡片【未开启】 */}
          {!appInfo?.FINANCE_QUANTIFY_SWITCH && (
            <>
              <AItradeTrendEmpty />
            </>
          )}
          {/* 后台卡片【开启】 */}
          {appInfo?.FINANCE_QUANTIFY_SWITCH && (
            <>
              <AItradeQuantificationView />
            </>
          )}
        </>
      )}
      {/* 定期理财 */}
      {+urlState?.type ===
        +FINANCIAL_MANAGEMENT_LIST_ENUM.REGULAR_FINANCIAL_MANAGEMENT && (
        <AItradeRegularView />
      )}
    </>
  );
};
